<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 511 511" class="fill-current">
    <g>
      <path d="M255.5,144C194.019,144,144,194.019,144,255.5S194.019,367,255.5,367S367,316.981,367,255.5S316.981,144,255.5,144z    M255.5,352c-53.21,0-96.5-43.29-96.5-96.5s43.29-96.5,96.5-96.5s96.5,43.29,96.5,96.5S308.71,352,255.5,352z" />
      <path d="M255.5,119c4.142,0,7.5-3.357,7.5-7.5V7.5c0-4.143-3.358-7.5-7.5-7.5S248,3.357,248,7.5v104   C248,115.643,251.358,119,255.5,119z" />
      <path d="M255.5,392c-4.142,0-7.5,3.357-7.5,7.5v104c0,4.143,3.358,7.5,7.5,7.5s7.5-3.357,7.5-7.5v-104   C263,395.357,259.642,392,255.5,392z" />
      <path d="M503.5,248h-104c-4.142,0-7.5,3.357-7.5,7.5s3.358,7.5,7.5,7.5h104c4.142,0,7.5-3.357,7.5-7.5S507.642,248,503.5,248z" />
      <path d="M119,255.5c0-4.143-3.358-7.5-7.5-7.5H7.5c-4.142,0-7.5,3.357-7.5,7.5s3.358,7.5,7.5,7.5h104   C115.642,263,119,259.643,119,255.5z" />
      <path d="M357.323,161.177c1.919,0,3.839-0.732,5.303-2.196l73.539-73.539c2.929-2.93,2.929-7.678,0-10.607   c-2.929-2.928-7.678-2.928-10.606,0l-73.539,73.539c-2.929,2.93-2.929,7.678,0,10.607   C353.484,160.444,355.404,161.177,357.323,161.177z" />
      <path d="M148.374,352.02l-73.539,73.539c-2.929,2.93-2.929,7.678,0,10.607c1.464,1.464,3.384,2.196,5.303,2.196   s3.839-0.732,5.303-2.196l73.539-73.539c2.929-2.93,2.929-7.678,0-10.607C156.051,349.092,151.302,349.092,148.374,352.02z" />
      <path d="M148.374,158.98c1.464,1.464,3.384,2.196,5.303,2.196s3.839-0.732,5.303-2.196c2.929-2.93,2.929-7.678,0-10.607   L85.441,74.834c-2.929-2.928-7.678-2.928-10.606,0c-2.929,2.93-2.929,7.678,0,10.607L148.374,158.98z" />
      <path d="M362.626,352.02c-2.929-2.928-7.678-2.928-10.606,0c-2.929,2.93-2.929,7.678,0,10.607l73.539,73.539   c1.464,1.464,3.384,2.196,5.303,2.196s3.839-0.732,5.303-2.196c2.929-2.93,2.929-7.678,0-10.607L362.626,352.02z" />
    </g>
  </svg>
</template>